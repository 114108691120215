import React, { Component } from "react";
import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse
} from "mdbreact";

const MIN_HEIGHT = 70;

class NavbarPage extends Component {
  state = {
    isTop: true,
    isOpen: false,
    overrideNavTransparency: false,
    activeUrl: '/'
  };

  navTopStyle = {
    height: '110px',
    fontSize: '16px',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }

  navStyle = {
    height: '110px',
    backgroundColor: 'black'
  }

  componentDidMount() {
    this.handleScroll();
    window.onscroll = () => {
      if (window.scrollY > 100) {
        this.setState({ isTop: false })
      } else {
        this.setState({ isTop: true })
      }
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  toggleCollapse = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
      setTimeout(() => {
        this.setState({ overrideNavTransparency: false })
      }, 350);
    } else {
      this.setState({ isOpen: true, overrideNavTransparency: true });
    }
  }

  updateActiveUrl(url) {
    if (window.innerWidth < 992) {
      this.toggleCollapse();
    }

    if (this.state.activeUrl === url && url !== '/#contact') {
      window.scrollTo(0, 0);
    }

    this.setState({
      activeUrl: url
    });

    if (url.includes('#')) {
      let urlSplit = url.split('#');
      if (urlSplit.length > 1) {
        let id = urlSplit[urlSplit.length - 1];
        this.scrollTo(id);
      }
    }
  }

  scrollTo(id) {
    document.getElementById(id).scrollIntoView();
  }

  handleScroll = () => {
    let scrollTop = window.scrollY,
      logoHeight = Math.max(MIN_HEIGHT, 130 - scrollTop);
    this.setState({
      logoHeight: logoHeight
    });
  }

  getLogoHeight = () => {
    let lHeight = this.state.overrideNavTransparency ? MIN_HEIGHT : this.state.logoHeight;
    return lHeight;
  }

  render() {
    const isDark = true;
    const isLight = false;
    const navClasses = this.state.overrideNavTransparency === true ? ' solid-bg-nav' : '';
    const contactUrl = window.location.pathname + '#contact'

    return (

      <MDBNavbar style={this.state.isTop ? this.navTopStyle : this.navStyle}
        className={'font-treb' + navClasses} fixed="top" dark={isDark} light={isLight} expand="lg">
        <MDBNavbarBrand className="ml-2 logo-padding text-uppercase nav-logo">
          <div className="logo-container">
            <a href="/" alt="home">
              <img className="logo-nav" src={require('../../assets/img/Prime-Group-Logo-Transparent-Background-Dark-Web.png')} style={{ height: (this.getLogoHeight() + 'px') }} alt="Prime Group Logo" />
            </a>
          </div>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse} />
        <MDBCollapse id="navbarCollapse2" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav right>
            <MDBNavItem className="mr-2" active={'/' === window.location.pathname} onClick={() => this.updateActiveUrl('/')}>
              <MDBNavLink to="/">Home</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mr-2" active={'/aboutus' === window.location.pathname} onClick={() => this.updateActiveUrl('/aboutus')}>
              <MDBNavLink to="/aboutus">About Us</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mr-2" active={'/latestprojects' === window.location.pathname} onClick={() => this.updateActiveUrl('/latestprojects')}>
              <MDBNavLink to="/latestprojects">For Sale</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mr-2" active={'/soldprojects' === window.location.pathname} onClick={() => this.updateActiveUrl('/soldprojects')}>
              <MDBNavLink to="/soldprojects">Sold Developments</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="mr-2" onClick={() => this.updateActiveUrl(contactUrl)}>
              <MDBNavLink to={contactUrl}>Contact Us</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>

    );
  }
}

export default NavbarPage;