import React, { useContext, useEffect, useState } from 'react'
import { RequestorContext } from '../../Data/StrapiRequestor';
import { MDBContainer, MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
import ProjectCard from './ProjectCard'
import LoadMoreButton from '../ButtonComponents/LoadMoreButton';

function ProjectCardMainPage(props) {

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(9);
    const [isMore, setIsMore] = useState(true);

    const ctx = useContext(RequestorContext);

    const getProjects = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.projectsLatest(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cProjects = projects.concat(data);
                    setProjects(cProjects);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => {})
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    useEffect(() => {
        getProjects();
    }, []);

    const cards = projects.map(p => <MDBCol md="6" lg="4" key={p.id}><ProjectCard {...p} toggleProject={props.toggleProject} /></MDBCol>);

    let btnMsg = 'View More';
    if(!projects || projects.length === 0) {
        btnMsg = 'No Developments';
    } else if(isMore) {
        btnMsg = 'View More';
    } else {
        btnMsg = 'End';
    }

    return (
        <div className="divider-padding darkgrey-bg white-text" id="developments">
            <MDBContainer>
                <div className="divider-margin">
                    {/* <MDBAnimation reveal type="fadeIn" className="fast">
                        <h3 className="h5-responsive text-center">Some more info about our homes, such as awards won or years of service... onsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h3>
                    </MDBAnimation> */}
                    {/* <hr className="red-color pl-4 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "100%", height: "1px" }} /> */}
                </div>
                <MDBAnimation reveal type="fadeIn" className="fast">
                    <h1 className="h1-responsive mb-4 text-white font-treb text-center">Our Current or Upcoming Homes for Sale</h1>
                </MDBAnimation>
                {/* <h6 className="heading-margin-lg h6-responsive text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At ultrices mi tempus imperdiet nulla malesuada pellentesque. Cursus sit amet dictum sit amet justo donec enim diam.</h6> */}
                <MDBAnimation reveal type="fadeIn" className="fast">
                    <MDBContainer>
                        <MDBRow style={{justifyContent: 'center'}}>
                            {cards}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <LoadMoreButton text={btnMsg} loading={loading} cb={getProjects} disabled={!isMore} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBAnimation>
            </MDBContainer>
        </div>
    );
};

export default ProjectCardMainPage;