
import React from 'react';
import StrapiRequestor from './Data/StrapiRequestor';


export function scrollTo(id) {
  var testDiv = document.getElementById(id);
  window.scrollTo({
    top: (testDiv.offsetTop - 50),
    behavior: 'smooth',
  });
}

export default function App() {
    return (
      <StrapiRequestor />
    );
}
