import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterPage = () => {
  return (
    <MDBFooter color="footer-color" className="font-small pt-2">
      <MDBContainer className="divider-margin footer-height font-hel mt-5 text-center text-md-left">
        <MDBRow className="mt-3">
          <MDBCol md="4" lg="5" xl="5" className="mb-4 m-lr-auto">
            <div className=" font-weight-bold">
              <h5 className="mb-3 grey-text">Contact Us</h5>
              <hr className="red accent-2 pl-4 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "10px", height: "1px" }} />
            </div>
            <p>
              <i className="fa fa-envelope mr-3" /><a href="mailto:mike@primegroup.nz" className="text-white">mike@primegroup.nz</a>
            </p>
            <p>
              <i className="fa fa-phone mr-3" /><a href="tel:+64277400700" className="text-white">0277 400 700</a>
            </p>
            <p>
              <i className="fa fa-map-marker-alt mr-3" />PO Box 8755, Riccarton, Christchurch 8440
            </p>
          </MDBCol>
          <MDBCol md="3" lg="3" xl="3" className="mb-4 m-lr-auto">
            <div className=" font-weight-bold">
              <h5 className="mb-3 grey-text">Navigation</h5>
              <hr className="red accent-2 pl-4 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "10px", height: "1px" }} />
            </div>
            <p>
              <a href="/">Home</a>
            </p>
            <p>
              <a href="/latestprojects">For Sale</a>
            </p>
            <p>
              <a href="/soldprojects">Sold Developments</a>
            </p>
            <p>
              <a href="/aboutus">About Us</a>
            </p>
            <p>
              <a href="#contact">Contact Us</a>
            </p>
          </MDBCol>
          <MDBCol md="5" lg="4" xl="4" className="mb-4 m-lr-auto">
            <div className=" font-weight-bold">
              <h5 className="mb-3 grey-text">Prime Group Limited</h5>
              <hr className="red accent-2 pl-4 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "10px", height: "1px" }} />
            </div>
            <div>
            Prime Group develops and builds executive homes primarily in the Fendalton, Merivale, Saint Albans and Central City areas of Christchurch, New Zealand.
            <br /><br />
            All Rights Reserved.

              {/* <MDBRow>
                <MDBCol md="12" lg="12" className="text-left divider-margin">
                  <span className="mb-4 grey-text">
                    See what we are up to on Social Media
                  </span>
                  <div>
                    <i className="fab fa-facebook-f white-text mr-4"> </i>
                  </div>
                  <div>
                    <i className="fab fa-twitter white-text mr-4 ml-4"> </i>
                  </div>
                  <div>
                    <i className="fab fa-google-plus-g white-text mr-4 ml-4"> </i>
                  </div>
                  <div>
                    <i className="fab fa-linkedin-in white-text mr-4 ml-4"> </i>
                  </div>
                  <div>
                    <i className="fab fa-instagram white-text mr-4 ml-4"> </i>
                  </div>
                </MDBCol>
              </MDBRow> */}
            </div>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol md="12" className="text-center mb-sm">
            <img className="logo-small-nav" src={require('../../assets/img/Prime-Group-Logo-Transparent-Background-Dark-Web.png')} alt="SMall Prime Group Logo" />
          </MDBCol>
        </MDBRow>
        <br />
        <hr className="grey d-inline-block mx-auto" style={{ opacity: "0.1", width: "100%", height: "0.5px" }} /> */}
      </MDBContainer>
      <div className="footer-copyright footer-padding font-text text-center">
        <MDBContainer fluid>
          <br />
          <div className="white-text text-uppercase pt-5">
            &copy; {new Date().getFullYear()} Copyright Prime Group 
          </div>
          <p className="text-uppercase mb-2 grey-text"><a href="http://xodesoftware.com/" rel="noopener noreferrer" alt="Designed by xodesoftware.com" target="_blank">Designed by Xode</a></p>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;