import React, { useState } from 'react';
import ProjectCardContainer from '../CardComponents/ProjectCardContainer';
import AboutSmall from '../AboutComponents/AboutSmall';

// Custom Imports 
import MainHeader from '../HeaderComponents/MainHeader'
import SelectedProject from '../SelectedProjectComponents/SelectedProject';

function HomePage() {
  const [project, setProject] = useState(null);
  const [showproject, setShowProject] = useState(null);

  const toggleProject = (project, show = false) => {
    setProject(project);
    setShowProject(show);
  }

  const projectPage = showproject ? (<SelectedProject project={project} toggleProject={toggleProject} />) : null;

  return (
    <>
      {projectPage}
      <MainHeader />
      <AboutSmall />
      <ProjectCardContainer toggleProject={toggleProject} />
    </>
  );
}

export default HomePage;