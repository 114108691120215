import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUsPage from "../Components/AboutComponents/AboutUsPage";
import Contact from "../Components/ContactComponents/Contact";
import Footer from "../Components/FooterComponent/Footer";
import HomePage from "../Components/HomePageComponents/HomePage";
import FixedNavbar from "../Components/NavComponents/FixedNavBar";
import LatestProjectsPage from "../Components/OurProjectsComponents/LatestProjectsPage";
import SoldProjectsPage from "../Components/OurProjectsComponents/SoldProjectsPage";

export const IS_LOADING = true;
export const LOADED = false;
export const RequestorContext = React.createContext();

export const STRAPI_URL = "https://admin.primegroup.nz";
// export const STRAPI_URL = 'http://localhost:8082';

export const CONTACT_MESSAGE_IDENTIFIER =
  "&dFNPkfPd3kXvyf6dktbPSpQ=MNZY?%gWqcQwu3JsaShsYs-AM?f6T8fYJYrAN^c";

export default class StrapiRequestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: "Hi, im test data :)",
    };
  }

  projectsLatest(start, limit) {
    let url = STRAPI_URL + "/projects";
    let query =
      "?_start=" +
      start +
      "&_limit=" +
      limit +
      "&sold=false&_sort=project_order:asc,created_at:desc";
    return fetch(url + query);
  }

  projectsSold(start, limit) {
    let url = STRAPI_URL + "/projects";
    let query =
      "?_start=" +
      start +
      "&_limit=" +
      limit +
      "&sold=true&_sort=project_order:asc,created_at:desc";
    return fetch(url + query);
  }

  project(id) {
    let url = STRAPI_URL + "/projects";
    return fetch(url + "/" + id);
  }

  projectsCount() {
    let url = STRAPI_URL + "/projects/count";
    return fetch(url);
  }

  render() {
    return (
      <RequestorContext.Provider value={{ requestor: this }}>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <Router>
            <FixedNavbar />
            <Switch>
              <Route path="/aboutus">
                <AboutUsPage />
              </Route>
              <Route path="/latestprojects">
                <LatestProjectsPage />
              </Route>
              <Route path="/soldprojects">
                <SoldProjectsPage />
              </Route>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
            <Contact />
            <Footer />
          </Router>
        </div>
      </RequestorContext.Provider>
    );
  }
}
