import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import ContactUsForm from './ContactUsForm';

export default class ContactUs extends Component {



    render() {
        return (
            <div className="darkgrey-bg white-text" id="contact">
                <div
                    id="map-container"
                    className="rounded z-depth-1-half map-container bg-30"
                >
                    <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11573.537274617644!2d172.60473660705426!3d-43.51517787003988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d322f4863c5ed01%3A0x500ef8684799945!2sChristchurch!5e0!3m2!1sen!2snz!4v1588231345339!5m2!1sen!2snz" width="100%" height="100%" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>                </div>
                <MDBContainer className="mt-5">
                    <MDBAnimation reveal type="fadeIn" className="slow">
                        <MDBCol>
                            <h1 className="heading-margin h1-responsive heading-margin text-center pt-4">We are ready to help you build your dream.</h1>
                            <h5 className="text-center h5-responsive mx-auto mb-5">Get in touch with our team today to talk about your dream home or any other questions you may have around the building process. We'd love to hear from you.</h5>
                            <MDBRow className="text-center">
                                <MDBCol md="4">
                                    <p><i className="fas fa-phone mt-4 fa-lg grey-text mr-2"></i><a href="tel:+64277400700" className="text-white">0277 400 700</a></p>
                                </MDBCol>
                                <MDBCol md="4">
                                    <p><i className="fas fa-envelope mt-4 fa-lg grey-text mr-2"></i><a href="mailto:mike@primegroup.nz" className="text-white">mike@primegroup.nz</a></p>
                                </MDBCol>
                                <MDBCol md="4">
                                    <p><i className="fas fa-map-marker-alt fa-lg mt-4 grey-text mr-2"></i>PO Box 8755, Riccarton, Christchurch 8440</p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBAnimation>
                </MDBContainer>
                <ContactUsForm />
            </div >
        );
    }
};