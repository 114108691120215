import React from 'react'
import { MDBContainer, MDBCol, MDBRow, MDBAnimation, MDBIcon, MDBBtn } from 'mdbreact'
import { scrollTo } from '../../App';

function AboutSmall() {
    return (
        <MDBRow className="bg-white">
            <MDBCol sm="12" md="12" lg="12">
                <MDBContainer className="mb-sm">
                    <br />
                    <div className="text-center">
                        <MDBAnimation reveal type="fadeIn" className="fast">
                            <h3 className="h3-responsive grey-text text-center pt-5">We are all unqiue and your home should be too. Our expertise is to cater for every bit of uniqueness in our luxury homes, building your dream.</h3>
                        </MDBAnimation>
                        <hr className="red-color pl-4 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "100%", height: "1px" }} />
                        <br />
                        <MDBAnimation reveal type="fadeIn" className="fast">
                            {/* <MDBCard className="text-center mt-5 mb-5 pb-5 pt-5">
                                <MDBCol md="12">
                                    <MDBCardBody> */}
                                        <h4 className="h4-responsive darkgrey-text mb-4">Prime Group is dedicated to building only the best executive homes in the heart of Christchurch's most sought after <b>established</b> suburbs with a focus on culture and lifestyle.
                                        </h4>
                                        {/* <br /><br />
                                        <MDBTypography blockquote><i>Where luxury meets lifestyle.</i></MDBTypography>
                                        <br />
                                        <p className="p-responsive">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed tempus urna et pharetra pharetra. Neque convallis a cras semper auctor.
                                                </p> */}
                                    {/* </MDBCardBody>
                                </MDBCol>
                            </MDBCard> */}
                        </MDBAnimation>
                        <MDBBtn outline color="black" style={{ minWidth: '235px' }} onClick={() => scrollTo('contact')}>Get in Touch</MDBBtn>
                        <br />
                        <br />
                        <br />
                        <MDBAnimation type="bounce" className="slower" infinite>
                            <MDBIcon icon="long-arrow-alt-down" size="2x" className="grey-text pr-3" />
                        </MDBAnimation>
                    </div>
                </MDBContainer>
            </MDBCol>
            <MDBCol sm="12" md="12" lg="12">
                {/* <Carousel /> */}
            </MDBCol>
        </MDBRow>
    )
}

export default AboutSmall;