import React from 'react';
import { MDBAnimation, MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact'
import Spinner from '../SpinnerComponent/Spinner';
import { CONTACT_MESSAGE_IDENTIFIER, STRAPI_URL, IS_LOADING, LOADED } from '../../Data/StrapiRequestor';

export default class ContactUsForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            loading: false,
            value: {}
        }
    }

    setLoading = (isLoading) => {
        this.setState({
            loading: isLoading
        });
    }

    sendMessage = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        // test form for validity
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            if (input != null && !input.validity.valid) {
                return;
            }
        }

        this.resetError();
        let values = this.state.value;
        values.identifier = CONTACT_MESSAGE_IDENTIFIER;

        this.setLoading(IS_LOADING);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.value)
        };

        fetch(STRAPI_URL + '/contact', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data === 200) {
                    this.setState({
                        message: 'Thanks for your message, we\'ll be in touch soon!'
                    });
                    this.resetForm();
                } else {
                    this.messageError();
                }
            })
            .catch(error => {
                this.messageError();
            })
            .finally(() => this.setLoading(LOADED));
    };

    resetError = () => {
        this.setState({
            message: '',
            error: false
        });
    }

    messageError = () => {
        this.setState({
            message: 'Oops something went wrong! Please try again.',
            error: true
        });
    }

    resetForm = () => {
        document.getElementById("contact-form").classList.remove("was-validated");
        this.setState({
            value: {
                identifier: '',
                name: '',
                email: '',
                subject: '',
                message: ''
            }
        });
    }

    changeHandler = event => {
        let values = this.state.value;
        values[event.target.name] = event.target.value;
        this.setState({
            value: values
        });
    };

    render() {
        const inputStyle = {
            color: 'whitesmoke'
        };

        return (
            <MDBContainer className="pb-5">
                    <MDBCol>

                        <MDBRow>

                            <MDBCol md="12">

                                <form id="contact-form" className="needs-validation" onSubmit={this.sendMessage} noValidate>

                                    <MDBRow>
                                        <MDBCol style={{ minHeight: '45px' }}>
                                            {
                                                this.state.message && this.state.message.length > 0 ? (
                                                    <>
                                                        <MDBAnimation type="bounceIn" duration="500ms">
                                                            <div style={{ textAlign: 'center', marginBottom: '15px', fontWeight: 500, fontSize: '20px', color: (this.state.error ? '#f44336' : '#8bc34a') }}>
                                                                {this.state.message}
                                                            </div>
                                                        </MDBAnimation>
                                                    </>
                                                ) : null
                                            }

                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>

                                        <MDBCol md="6">
                                            <div>
                                                <MDBInput type="text" label="Your Name" size="lg" id="name" name="name" onChange={this.changeHandler} value={this.state.value.name} style={inputStyle} disabled={this.state.loading} required />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                        </MDBCol>

                                        <MDBCol md="6">
                                            <div>
                                                <MDBInput type="email" label="Your Email" id="email" name="email" size="lg" onChange={this.changeHandler} value={this.state.value.email} style={inputStyle} disabled={this.state.loading} required />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                        </MDBCol>

                                    </MDBRow>

                                    <MDBRow>

                                        <MDBCol md="12">
                                            <div>
                                                <MDBInput type="text" label="Subject" size="lg" id="subject" name="subject" onChange={this.changeHandler} value={this.state.value.subject} style={inputStyle} disabled={this.state.loading} required />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                        </MDBCol>

                                    </MDBRow>

                                    <MDBRow>

                                        <MDBCol md="12">
                                            <div>
                                                <MDBInput type="textarea" label="Message" size="lg" id="message" name="message" onChange={this.changeHandler} value={this.state.value.message} style={inputStyle} disabled={this.state.loading} required />
                                                <div className="invalid-feedback">
                                                    Required.
                                                </div>
                                            </div>
                                        </MDBCol>

                                    </MDBRow>

                                    <MDBRow style={{ textAlign: 'left' }}>

                                        <MDBCol className="text-center pt-3" md="12">
                                            <MDBBtn outline color="white" type="submit">
                                                <Spinner loading={this.state.loading} text={'Send Message'} />
                                            </MDBBtn>
                                        </MDBCol>

                                    </MDBRow>

                                </form>

                            </MDBCol>

                        </MDBRow>
                    </MDBCol>
            </MDBContainer>
        );
    }
}