import React, { useEffect, useContext, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import ProjectCard from '../CardComponents/ProjectCard';
import ProjectHeader from '../HeaderComponents/ProjectsHeader'
import ScrollToTopOnMount from '../NavComponents/ScrollToTop';
import { RequestorContext } from '../../Data/StrapiRequestor';
import LoadMoreButton from '../ButtonComponents/LoadMoreButton';
import SelectedProject from '../SelectedProjectComponents/SelectedProject';

function LatestProjectsPage() {

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(9);
    const [isMore, setIsMore] = useState(true);
    const [project, setProject] = useState(null);
    const [showproject, setShowProject] = useState(null);

    const toggleProject = (project, show = false) => {
        setProject(project);
        setShowProject(show);
    }

    const ctx = useContext(RequestorContext);

    const getProjects = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.projectsLatest(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cProjects = projects.concat(data);
                    setProjects(cProjects);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => { })
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    useEffect(() => {
        getProjects();
    }, []);

    const projectPage = showproject ? (<SelectedProject project={project} toggleProject={toggleProject} />) : null;
    const cards = projects.map(p => <MDBCol md="4" key={p.id}><ProjectCard {...p} toggleProject={toggleProject} /></MDBCol>);

    return (
        <>
            {projectPage}
            <div>
                <ScrollToTopOnMount />
                <ProjectHeader />
                <div className="bg-white divider-padding">
                    <div className="divider-margin">
                        <MDBContainer className="text-center">
                            <MDBAnimation reveal type="fadeIn" className="fast">
                                <h3 className="heading-margin heading-h3-sm heading-h3-lg grey-text pt-4 text-center mb-5">Current or Upcoming Homes For Sale.</h3>
                            </MDBAnimation>
                            <MDBContainer>
                                <MDBRow>
                                    {cards}
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <LoadMoreButton text={isMore ? "View More" : "End"} loading={loading} cb={getProjects} disabled={!isMore} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBContainer>
                    </div>
                </div>
            </div>
        </>

    );
};

export default LatestProjectsPage;