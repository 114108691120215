import React, { useEffect, useState, useContext } from 'react';
import { RequestorContext } from '../../Data/StrapiRequestor';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import ProjectHeader from '../HeaderComponents/ProjectsSoldHeader'
import ScrollToTopOnMount from '../NavComponents/ScrollToTop';
import LoadMoreButton from '../ButtonComponents/LoadMoreButton';
import SelectedProject from '../SelectedProjectComponents/SelectedProject';
import ProjectCard from '../CardComponents/ProjectCard';

function SoldProjectsPage() {

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(9);
    const [isMore, setIsMore] = useState(true);
    const [project, setProject] = useState(null);
    const [showproject, setShowProject] = useState(null);

    const toggleProject = (project, show = false) => {
        setProject(project);
        setShowProject(show);
    }

    const ctx = useContext(RequestorContext);

    const getProjects = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.projectsSold(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cProjects = projects.concat(data);
                    setProjects(cProjects);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => { })
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    useEffect(() => {
        getProjects();
    }, []);

    const projectPage = showproject ? (<SelectedProject project={project} toggleProject={toggleProject} />) : null;
    const cards = projects.map(p => <MDBCol md="4" key={p.id}><ProjectCard {...p} toggleProject={toggleProject} /></MDBCol>);

    return (
        <div>
            {projectPage}
            <ScrollToTopOnMount />
            <ProjectHeader />
            <div className="divider-padding bg-white">
                <div className="divider-margin">
                    <MDBContainer className="text-center">
                        <MDBAnimation reveal type="fadeIn" className="fast">
                            <h3 className="heading-margin heading-h3-sm heading-h3-lg grey-text pt-4 text-center mb-5">A selection of some of our recent homes.</h3>
                        </MDBAnimation>
                        <MDBRow>
                            {cards}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <LoadMoreButton text={isMore ? "View More" : "End"} loading={loading} cb={getProjects} disabled={!isMore} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
        </div >
    );
};

export default SoldProjectsPage;