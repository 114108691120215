import React from 'react';
import { MDBBtn } from 'mdbreact';
import './style.css';

export default function LoadMoreButton(props) {

    return (
        <div className="load-more-container">
            {
                props.loading ?
                    <div className="bar2"></div>
                    :
            <MDBBtn className="load-more-btn" size="sm" onClick={() => props.cb()} disabled={props.disabled}>{props.text}</MDBBtn>
            }
        </div>
    )
}