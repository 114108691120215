import React from 'react'
import { MDBContainer, MDBAnimation, MDBRow, MDBCol, MDBView } from 'mdbreact'
import AboutHeader from '../HeaderComponents/AboutHeader'
import ScrollToTopOnMount from '../NavComponents/ScrollToTop';
import { scrollTo } from '../../App';

function AboutUsPage() {
    return (
        <>
            <ScrollToTopOnMount />
            <AboutHeader />
            <div className="bg-white">
                <MDBAnimation reveal type="fadeIn" className="slow">
                    <MDBContainer className="text-center divider-padding">
                        <MDBRow className="mt-5">
                            <MDBCol md="6" lg="6">
                                <img src={require('../../assets/img/mike.jpg')} className="text-left img-fluid" alt="Mike Bryant" style={{ maxHeight: '400px', borderRadius: '10px'}}/>
                            </MDBCol>
                            <MDBCol md="6" lg="6">
                                <hr className="grey accent-2 mt-4 mb-4 d-inline-block mx-auto" style={{ width: "20%", height: "1px" }} />
                                <h3 className="heading-margin heading-h3-sm heading-h3-lg grey-text text-center">Mike Bryant, Director of Prime Group, has been building homes for approximately 20 years. During this time Mike has built hundreds of homes throughout Canterbury, with the majority being for Design and Build clients on their own land, whilst also building homes to sell.</h3>
                                <hr className="grey accent-2 mt-4 d-inline-block mx-auto" style={{ width: "20%", height: "1px" }} />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    {/* <MDBRow>
                            <MDBCol md="12">
                                <h3 className="h5-responsive text-center grey-text">Mike Bryant, Director of Prime Group, has been building homes for approximately 20 years. During this time, Mike has built hundreds of homes throughout Canterbury, with the majority being for Design and Build clients on their own land, as well as building homes to sell.</h3>
                            </MDBCol>
                        </MDBRow> */}
                    <div className="bg-darker">
                        <MDBContainer className="divider-margin-lg text-center">
                            <h1 className="h1-responsive mb-5 pt-2">Prime Group has built up a great team of preferred suppliers and contractors to ensure their work is to the same high standards that Mike expects on every job.</h1>
                            <hr className="red accent-2 mb-4 mt-4 d-inline-block mx-auto" style={{ width: "80%", height: "1px" }} />
                            <h3 className="h3-responsive white-text text-left mt-5 mb-4">Over the last 5 years Prime Group has concentrated on developing and building homes to sell in the Fendalton, Merivale, Central City and Saint Albans areas of Christchurch. Prime Group has built up a great team of preferred suppliers and contractors to ensure their work is to the same high standards that Mike expects on every job.
                             </h3>
                            <MDBRow>
                                <MDBCol sm="12" md="12" lg="5">
                                    <div className="text-left center">
                                            <h3 className="h3-responsive text-left white-text">Mike is heavily involved in every build from liaising with architects, councils, surveyors, engineers, suppliers, contractors as well overseeing the Project Management and running of each development.
                                         </h3>
                                    </div>
                                </MDBCol>
                                <MDBCol sm="12" md="12" lg="5" className="mt-5 container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/about-picture.png")}
                                            className="img-fluid border5"
                                            alt="prime group home"
                                        />
                                    </MDBView>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </MDBAnimation>
                <MDBContainer className="text-right">
                    {/* <MDBAnimation reveal type="flipInX" className="slow">
                        <MDBBtn outline color="red" className="waves-effect mb-4" onClick={() => scrollTo('/latestprojects')}>See Our Developments</MDBBtn>
                    </MDBAnimation> */}
                </MDBContainer>
            </div>
        </>
    );
};

export default AboutUsPage;