import { MDBAnimation, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import React from "react";
import "./style.css";
const r = React;

const ProjectCard = (props) => {
  const suburb =
    props.suburb && props.suburb.Name ? ", " + props.suburb.Name : null;
  const city = props.city && props.city.Name ? ", " + props.city.Name : null;
  const isSold = props.sold;

  return (
    <MDBAnimation reveal type="fadeIn" className="faster">
      {/* <MDBContainer style={{ width: "100%" }}> */}
      <div
        className="card elegant-color-dark divider-margin-sm"
        style={{ height: "440px" }}
      >
        <div
          className="view"
          style={{
            position: "relative",
          }}
        >
          <img
            src={props.primaryImage.url}
            className="card-img-top height-150"
            alt="Prime Group project"
          />
          <div className="mask rgba-white-slight"></div>
          {isSold && <div className="sold-container">SOLD</div>}
        </div>
        <div className="card-body text-center">
          <h4 className="pcc-title text-center">{props.title}</h4>
          <hr
            className="grey accent-2 mb-2 d-inline-block mx-auto mt-0"
            style={{ width: "100%" }}
          />
          <div className="pcc-body">
            <div className="lc-address-text">
              {props.address}
              {suburb}
              {city}
            </div>
          </div>
          <div style={{ height: "55px" }}>
            {/* Ammenities Start */}
            <div className="text-left">
              <MDBRow>
                <MDBCol sm="12" className="pt-1 opacity-sm">
                  {props.bedrooms > 0 ? (
                    <span className="lc-ammenity">
                      {props.bedrooms}{" "}
                      <MDBIcon className="lc-ammenity-icon mr-3" icon="bed" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.bathroom > 0 ? (
                    <span className="lc-ammenity">
                      {props.bathroom}{" "}
                      <MDBIcon className="lc-ammenity-icon" icon="bath" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.living > 0 ? (
                    <span className="lc-ammenity">
                      {props.living}{" "}
                      <MDBIcon className="lc-ammenity-icon" icon="couch" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.kitchen > 0 ? (
                    <span className="lc-ammenity">
                      {props.kitchen}{" "}
                      <MDBIcon className="lc-ammenity-icon" icon="utensils" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.office > 0 ? (
                    <span className="lc-ammenity">
                      {props.office}{" "}
                      <MDBIcon className="lc-ammenity-icon" icon="desktop" />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.garage > 0 ? (
                    <span className="lc-ammenity">
                      {props.garage}{" "}
                      <MDBIcon className="lc-ammenity-icon" icon="car" />
                    </span>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
            </div>
            <hr
              className="grey accent-2 mb-2 d-inline-block mx-auto mt-0"
              style={{ width: "100%", bottom: "0px" }}
            />
          </div>
          {/* <p className="card-text white-text project-card-body">{ReactHtmlParser(props.blurb)}</p> */}
          <div
            className="btn btn-outline-white btn-md waves-effect"
            onClick={() => props.toggleProject(props, true)}
          >
            View
          </div>
        </div>
      </div>
      {/* </MDBContainer> */}
    </MDBAnimation>
  );
};

export default ProjectCard;
