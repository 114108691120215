import React from 'react';
import { MDBAnimation } from 'mdbreact';

function MainHeader() {
    return (
        <header>
            <div className="bg-dark-header">
                <div className="pt-4 flex-center flex-column text-white text-left header-container">
                    <div className="float-left text-left text-wrap mr-3 header-content">
                        <h1 className="heading-main-sm heading-main-md heading-main-lg">Sold Developments</h1>
                        <MDBAnimation reveal type="slideInRight" className="slow">
                            <hr className="red-color pl-4 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "30%", height: "2px" }} />
                        </MDBAnimation>
                    </div>
                </div>
            </div>
        </header>
    );
};


export default MainHeader;