import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { IS_LOADING, LOADED } from "../../Data/StrapiRequestor";
import "./style.css";

const r = React;
const c = Component;
class SelectedProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.project,
      loading: IS_LOADING,
      error: false,
      top: 0,
      height: window.innerHeight,
      images: [],
    };
    this.applyScrollLock(true);
  }

  applyScrollLock = (locked = false) => {
    if (locked) {
      document.body.className = "no-scroll";
      window.iNoBounce.enable();
    } else {
      document.body.className = "";
      window.iNoBounce.disable();
    }
  };

  componentDidMount() {
    this.setTop(0);
    this.listenToOrientation(true);
    this.setImageArray();
    this.updateLoading(LOADED);
  }

  listenToOrientation(listen = false) {
    const setTop = this.setTop;
    if (listen) {
      window.addEventListener("orientationchange", () => {
        setTop();
      });
    } else {
      window.removeEventListener("orientationchange", () => {
        setTop();
      });
    }
  }

  componentWillUnmount() {
    this.applyScrollLock();
  }

  updateLoading = (loadState) => {
    setTimeout(() => {
      this.setState({
        loading: loadState,
      });
    }, 800);
  };

  setImageArray = () => {
    let imageArr = [];
    if (this.state.data) {
      let url = this.state.data.primaryImage.url;
      let thumbUrl = this.getImageThumb(this.state.data.primaryImage);
      imageArr.push({ original: url, thumbnail: thumbUrl });
      for (let i = 0; i < this.state.data.extraImages.length; i++) {
        url = this.state.data.extraImages[i].url;
        thumbUrl = this.getImageThumb(this.state.data.extraImages[i]);
        imageArr.push({ original: url, thumbnail: thumbUrl });
      }
    }

    this.setState({
      images: imageArr,
    });
  };

  getImageThumb = (image) => {
    if (image.formats && image.formats.small && image.formats.small.url) {
      return image.formats.small.url;
    } else {
      return image.url;
    }
  };

  close = () => {
    this.applyScrollLock();
    this.state.data.toggleProject(this.state.data, false);
  };

  setTop = (timeout = 250) => {
    setTimeout(() => {
      const top = window.scrollY;
      const height = window.innerHeight;
      if (top !== this.state.top) {
        this.setState({
          top: top,
          height: height,
        });
      }
    }, timeout);
  };

  renderLeftNav = (onClick, disabled) => {
    return (
      <div
        className="image-gallery-custom-left-nav"
        disabled={disabled}
        onClick={onClick}
      >
        <MDBIcon
          icon="chevron-left"
          className="image-gallery-custom-left-nav-icon"
        />
      </div>
    );
  };

  renderRightNav = (onClick, disabled) => {
    return (
      <div
        className="image-gallery-custom-right-nav"
        disabled={disabled}
        onClick={onClick}
      >
        <MDBIcon
          icon="chevron-right"
          className="image-gallery-custom-right-nav-icon"
        />
      </div>
    );
  };

  renderPlayPauseButton = (onClick, isPlaying) => {
    return (
      <div
        // type='button'
        className={"image-gallery-custom-play-button"}
        onClick={onClick}
      >
        {isPlaying ? (
          <MDBIcon icon="stop" className="image-gallery-custom-play-icon" />
        ) : (
          <MDBIcon icon="play" className="image-gallery-custom-play-icon" />
        )}
      </div>
    );
  };

  renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <div
        // type='button'
        className={"image-gallery-custom-fullscreen-button"}
        onClick={onClick}
      >
        {isFullscreen ? (
          <MDBIcon
            icon="compress"
            className="image-gallery-custom-fullscreen-icon"
          />
        ) : (
          <MDBIcon
            icon="expand"
            className="image-gallery-custom-fullscreen-icon"
          />
        )}
      </div>
    );
  };

  renderItem = (data) => {
    return (
      <div
        className="image-gallery-custom-render-item"
        style={{ backgroundImage: `url(${data.original})` }}
      ></div>
    );
  };

  getAskingPrice = () => {
    if (
      !this.state.data ||
      !this.state.data.askingPrice ||
      this.state.data.askingPrice === 0 ||
      this.state.data.askingPrice.startsWith("0")
    )
      return null;

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(this.state.data.askingPrice);
  };

  render() {
    const isSold = this.state.data.sold;
    this.authCtx = this.context.auth;
    this.setTop();
    const suburb =
      this.state.data.suburb && this.state.data.suburb.Name
        ? ", " + this.state.data.suburb.Name
        : null;
    const city =
      this.state.data.city && this.state.data.city.Name
        ? ", " + this.state.data.city.Name
        : null;

    return (
      <div
        className="selected-listing-container"
        style={{ top: this.state.top }}
      >
        {/* <div className="back-container">
                    <div className="back-inner" onClick={() => this.close()}>
                        <MDBIcon icon="arrow-left" className="back-icon" />
                        <div className="back-text">Back</div>
                    </div>
                </div> */}
        <div className="close-outer-container">
          <div className="close-container" onClick={() => this.close()}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
            <div className="close-x close-label">close</div>
          </div>
        </div>

        <div className="selected-listing-inner-container">
          <div className="selected-listing-inner-content">
            <MDBRow style={{ height: "10px" }}>
              <MDBCol>
                {this.state.loading ? <div className="bar2"></div> : null}
              </MDBCol>
            </MDBRow>

            {this.state.loading ? null : (
              <>
                <MDBRow>
                  <MDBCol size="12">
                    <div className="selected-listing-title">
                      {this.state.data.title}
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <ImageGallery
                        items={this.state.images}
                        showIndex={true}
                        useBrowserFullscreen={false}
                        renderItem={this.renderItem}
                      />
                      {isSold && (
                        <div className="sold-container-large">SOLD</div>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow> */}
                <MDBCol size="12">
                  <div className="mt-4 selected-listing-address">
                    {ReactHtmlParser(this.state.data.address)}
                    {suburb}
                    {city}
                  </div>
                  <hr
                    className="red-color pl-2 pr-2 mt-1 mb-3 mt-0 d-inline-block mx-auto"
                    style={{ width: "90%", height: "1px" }}
                  />
                </MDBCol>
                {!this.getAskingPrice() ? (
                  <MDBCol sm="12">
                    {this.state.data.saleType ? (
                      <div className="selected-listing-sub-title ml-2">
                        {this.state.data.saleType.Name}
                      </div>
                    ) : null}
                  </MDBCol>
                ) : (
                  <MDBCol sm="12">
                    <div className="selected-listing-sub-title ml-2">
                      Asking Price {this.getAskingPrice()}
                    </div>
                  </MDBCol>
                )}
                <div className="text-left pl-2">
                  <MDBRow className="pt-1 pr-3 opacity-sm">
                    <MDBCol
                      md="12"
                      style={{ paddingLeft: "30px", marginBottom: "10px" }}
                    >
                      {this.state.data.bedrooms > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.bedrooms}{" "}
                          <MDBIcon
                            className="lc-ammenity-icon mr-3"
                            icon="bed"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.data.bathroom > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.bathroom}{" "}
                          <MDBIcon className="lc-ammenity-icon" icon="bath" />
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.data.living > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.living}{" "}
                          <MDBIcon className="lc-ammenity-icon" icon="couch" />
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.data.kitchen > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.kitchen}{" "}
                          <MDBIcon
                            className="lc-ammenity-icon"
                            icon="utensils"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.data.office > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.office}{" "}
                          <MDBIcon
                            className="lc-ammenity-icon"
                            icon="desktop"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.data.garage > 0 ? (
                        <span className="lc-ammenity">
                          {this.state.data.garage}{" "}
                          <MDBIcon className="lc-ammenity-icon" icon="car" />
                        </span>
                      ) : (
                        ""
                      )}
                      {/* </MDBCol>
                                                <MDBCol md="12" className="mt-3" style={{ paddingLeft: "30px" }}> */}
                      {this.state.data.floorSqm > 0 ? (
                        <h6 className="selected-listing-sub-title-sm mt-2">
                          Floor Area &nbsp; {this.state.data.floorSqm}&#13217;
                          &nbsp;
                        </h6>
                      ) : null}

                      {this.state.data.landSqm > 0 ? (
                        <h6 className="selected-listing-sub-title-sm">
                          Land Area &nbsp; {this.state.data.landSqm}&#13217;
                          &nbsp;
                        </h6>
                      ) : null}
                    </MDBCol>
                  </MDBRow>
                </div>
                <MDBCol md="12">
                  {this.state.data.bedrooms > 0 ? (
                    <span>
                      <hr
                        className="grey pl-2 pr-2 mt-1 mb-3 mt-0 d-inline-block mx-auto"
                        style={{ width: "90%", bottom: "0px" }}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </MDBCol>
                <MDBCol md="10">
                  <div className="selected-listing-blurb h6-responsive">
                    {ReactHtmlParser(this.state.data.blurb)}
                  </div>
                </MDBCol>
                {/* </MDBRow> */}
                {/* <div className="ml-3">
                                        <hr className="red-color pl-2 pr-2 ml-4 mt-4 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "90%", height: "1px" }} />
                                        <div className="text-left">
                                            <h4 className="selected-listing-contact">Interested? Get in touch with one of our sales consultants to find out more.</h4>
                                            <MDBRow>
                                                <MDBCol md="3" className="mx-auto white-border rounded-3">
                                                    <div className="text-left my-5">
                                                        <div className="text-left">
                                                            <img src={require('../../assets/img/bridget.png')} alt="bridget" className="w-100 img-fluid" style={{ maxWidth: "200px" }} />
                                                        </div>
                                                        <h3 className="mt-4 mb-2 selected-listing-contact h6-responsive">Bridget Perry</h3>
                                                        <h5 className="font-weight-md grey-text">Sales & Marketing Consultant</h5>
                                                        <div className="text-left mt-3" style={{ fontSize: '16px', letterSpacing: '1px' }}>
                                                            <a className="white-text mb-2" href="tel:+6421402071"><i className="fa fa-phone mr-3 white-text" />+64 21 402 071</a><br />
                                                            <a className="white-text" href="mailto:bridget@ere.nz"><i className="fa fa-envelope mr-3 white-text" />bridget@ere.nz</a>
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md="3" className="mx-auto white-border rounded-3">
                                                    <div className="text-left my-5">
                                                        <div className="text-left">
                                                            <img src={require('../../assets/img/fiona.png')} alt="fiona" className="w-100 img-fluid" style={{ maxWidth: "200px" }} />
                                                        </div>
                                                        <h3 className="mt-4 mb-2 selected-listing-contact h6-responsive">Fiona Williams</h3>
                                                        <h5 className="font-weight-md grey-text">Sales Consultant</h5>
                                                        <div className="text-left mt-3" style={{ fontSize: '16px', letterSpacing: '1px' }}>
                                                            <a className="white-text mb-2" href="tel:+64272333804"><i className="fa fa-phone mr-3 white-text" />+64 27 233 3804</a><br />
                                                            <a className="white-text" href="mailto:fiona@ere.nz"><i className="fa fa-envelope mr-3 white-text" />fiona@ere.nz</a>
                                                        </div>
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                        <hr className="grey pl-2 pr-2 mt-1 mb-3 mt-0 d-inline-block mx-auto" style={{ width: "90%", bottom: "0px" }} />
                                    </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectedProject;
