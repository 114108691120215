import React from 'react';
import { MDBAnimation, MDBBtn } from 'mdbreact';
import './HeaderStyle.css'
// import { Parallax } from 'react-parallax';
import { scrollTo } from '../../App';
import { Fade } from 'react-slideshow-image';

function MainHeader() {

    const fadeImages = [
        require('../../assets/img/home-1.png'),
        require('../../assets/img/home-2.png'),
        require('../../assets/img/home-3.png')
    ];

    const fadeProperties = {
        duration: 4000,
        transitionDuration: 2500,
        infinite: true,
        indicators: false,
        onChange: (oldIndex, newIndex) => {
            
        }
    }

    const slides = fadeImages.map((p, i) => {
        return (
            <div className="each-fade">
                <div className="image-container" style={{ backgroundImage: `url(${p})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                </div>
            </div>
        )
    })

    return (
        <header>

            {/* <MDBMask>
                <Parallax
                    bgImage={require('../../assets/img/home-home.png')}
                    bgImageAlt="Prime Group Image Header"
                    strength={400}
                >
                    <Parallax
                    bgImage={require('../../assets/img/header.png')}
                    bgImageAlt="Prime Group Image Header"
                    strength={400}
                    className="parallax-img-out hide-opacity"
                > */}

            <div className="slide-container">
                <Fade {...fadeProperties}>
                    {slides}
                </Fade>
            </div>
            <div style={{ height: '100vh' }} className="header-inner">
                <div className="par-heading" style={{ maxWidth: '75%', color: '#f4f4f4f4' }}>
                    <div className="float-left text-left text-wrap mr-3 ml-3">
                        <h1 className="display-heading display-heading-sm heading-h1-sm">Building your dream.</h1>
                        <MDBAnimation reveal type="slideInRight" className="slow">
                            <hr className="red-color pl-4 mb-4 mt-2 d-inline-block mx-auto" style={{ width: "100px", height: "2px" }} />
                        </MDBAnimation>
                        <h4 className="heading-tagline">Christchurch property developer passionate about <b className="red-color-text">Executive</b> Homes.</h4>
                        <br />
                        <MDBBtn outline color="white" style={{ minWidth: '235px' }} onClick={() => scrollTo('developments')}>Find Your Dream Home</MDBBtn>
                    </div>
                </div>
            </div>
            {/* </Parallax>
                </Parallax>
            </MDBMask> */}


        </header>
    );
};


export default MainHeader;